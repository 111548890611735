import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { DepositPanel } from 'pages/NewDashboard/components/DepositPanel';
import { WithdrawPanel } from 'pages/NewDashboard/components/Withdraw';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const Wrapper = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 40px;
	column-gap: 40px;
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
`;

const Text = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>`
	color: ${({ isActive }) => (isActive ? '#b9886d' : '#b3b3bb')};
	margin-right: 20px;
	font-weight: 600;

	:hover {
		cursor: pointer;
		color: #bd937c;
	}
`;

const Withdraw = styled('div')`
	border-radius: 16px;
	background-color: #f0ece8;
	width: 100%;
	max-width: 500px;
	height: 100%;
	padding: 40px;
`;

const Title = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'start',
	fontSize: 36,
	fontWeight: 'bold',
	marginBottom: 20,
}));

const StyledBox = styled('div')(() => ({
	display: 'inline',
	padding: '6px 13px 12px 13px',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: '#b9886d',
	borderRadius: 8,
	width: 'min-content',
	height: 'min-content',
}));

enum Tabs {
	Deposit = 'Deposit',
	WithDraw = 'WithDraw',
}

export const NewDashboard = () => {
	const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Deposit);
	const selectedVault = useSelector((state: RootState) => state.vault);

	return (
		<Wrapper>
			<Withdraw>
				<Title>
					<StyledBox>
						<Typography
							variant="h5"
							color="#fff"
							fontWeight={800}
							display={'inline'}
						>
							ai
						</Typography>
					</StyledBox>
					<Box width={16} />
					<Typography
						variant="h4"
						fontWeight={800}
						display={'inline'}
					>
						agent {selectedVault.name}
					</Typography>
				</Title>
				<Row>
					<Text
						isActive={activeTab === Tabs.Deposit}
						onClick={() => setActiveTab(Tabs.Deposit)}
					>
						Deposit
					</Text>
					<Text
						isActive={activeTab === Tabs.WithDraw}
						onClick={() => setActiveTab(Tabs.WithDraw)}
					>
						Withdraw
					</Text>
				</Row>
				<Box height={16} />
				{activeTab === Tabs.Deposit && <DepositPanel />}
				{activeTab === Tabs.WithDraw && <WithdrawPanel />}
			</Withdraw>
		</Wrapper>
	);
};
