import { WALLETS } from 'data/supportedWallet';

interface Network {
	name: string;
	image: string;
	value: string;
}

export interface TotalTvl {
	month: string;
	Value: number;
}

export interface Exchanger {
	name: string;
	image: string;
	value: string;
	estApy: string;
	totalUSDC: number | string;
	totalTvl: TotalTvl[];
	network?: Network[];
	aptosContractAddress?: string;
	tvl?: string;
	depositDisabled?: boolean;
	contractAddress?: string;
}

export interface Vault {
	id: number;
	name: string;
	estApy: string;
	currency: string;
	type: string;
	shortDescription: string;
	logo: string;
	darkLogo: string;
	exchanger: Exchanger[];
	token: {
		name: string;
		icon: string;
		alt: string;
		address: string;
		decimalValue: number;
		maxAllowed: number;
		minAllowed: number;
	}[];
	isActive: boolean;
	wallets: WALLETS[];
}

// Define the data using the defined types
export const VAULTS: { [keys: string]: Vault[] } = {
	Base: [
		{
			id: 0,
			name: 'Dudu',
			currency: '$',
			type: 'Earn Max points with high capital efficiency', // "High-Risk, High-Reward",
			shortDescription: 'Market savvy bear/AI agent',
			logo: '/images/atlas-brand.png',
			darkLogo: '/images/Atlas-white.png',
			estApy: '28%',
			exchanger: [
				{
					name: 'Bsx',
					value: 'bsx',
					image: '/images/exchange/bsx.png',
					estApy: '28%',
					totalUSDC: '',
					tvl: '400k',
					contractAddress:
						'0x57e0F55126d1c37D8Ad7b5d384B59cB9b61AADFA',
					totalTvl: [
						{ month: 'Dec', Value: 0.1 },
						{ month: 'Jan', Value: 0.4 },
						{ month: 'Feb', Value: 1 },
						{ month: 'Mar', Value: 10 },
						{ month: 'Apr', Value: 30 },
						{ month: 'May', Value: 50 },
						{ month: 'June', Value: 82 },
						{ month: 'July', Value: 110 },
						{ month: 'Aug', Value: 1400 },
					],
					depositDisabled: false,
					network: [
						{
							name: 'Base',
							value: 'base',
							image: '/crypto/base.svg',
						},
					],
				},
			],
			token: [
				{
					address: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913', // update the address
					decimalValue: 6,
					maxAllowed: 100000,
					minAllowed: 5000,
					name: 'USDC',
					icon: '/images/crypto/usdc.png',
					alt: 'USDC',
				},
			],
			isActive: true,
			wallets: [WALLETS.metamask],
		},
	],
};
