import React, { useCallback, useEffect, useState } from 'react';
import { AmountInputBox } from 'pages/Landing/components/AmountInputBox';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Vault, VAULTS } from 'data/Vaults';
import { ItemCard } from 'pages/Landing/components/ItemCard';
import { useNetwork } from 'hooks/useNetwork';
import { getErc20Contract } from 'utils/contractHelpers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { getDepositContract } from 'utils/depositContractHelpers';
import { toast } from 'react-toastify';
import { userDeposit } from 'apis';
import { useEthersProvider } from 'hooks/useEtherProvider';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { useAccount } from 'wagmi';
import { appActions } from 'store/app';
import { getErrorMessage } from 'data/errors';

const Row = styled('div')`
	display: grid;
	grid-template-columns: repeat(8, 1fr);
`;

const Text = styled(Typography)`
	font-weight: 500;

	:hover {
		color: #235ee1;
		cursor: pointer;
	}
`;

const Column = styled('div')`
	display: flex;
	flex-direction: column;
`;

const SpaceBetween = styled(Column)`
	height: 100%;
	justify-content: space-between;
`;

const StyledRow = styled('div')`
	display: flex;
	flex: 1 1;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
	column-gap: 16px;
`;

const StyledButton = styled(Button)`
	background-color: #b9886d;

	:hover {
		background-color: #bb805f;
	}
`;

export const DepositPanel = () => {
	const [loading, setLoading] = useState(false);
	const [allowance, setAllowance] = useState('0');
	const [balance, setBalance] = useState('0');
	const [contractBal, setContractBal] = useState('0');
	const [amount, setAmount] = useState('');

	const { address: account, isConnected } = useAccount();
	const dispatch = useDispatch();
	const appState = useSelector((state: RootState) => state.app);
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const selectedExchange = selectedVault.exchanger.find(
		(d) => d.value === selectedVault.exchange,
	);

	useNetwork();

	const provider: any = useEthersProvider();
	const signer: any = useEthersSigner();

	// Check if deposit should be disabled based on min/max allowed
	const isDisabled =
		parseFloat(amount || '0') + parseFloat(contractBal) >=
			(selectedAsset?.maxAllowed || 10000) ||
		parseFloat(amount || '0') + parseFloat(contractBal) <
			(selectedAsset?.minAllowed || 0);
	const disabledMessage =
		Number(amount) < (selectedAsset?.minAllowed || 0)
			? `Min ${selectedAsset?.minAllowed || 0} USDC allowed`
			: `${selectedAsset?.minAllowed || 0}-${
					selectedAsset?.maxAllowed || 10000
			  } Currently in beta testing`;

	const formattedBalance = Number(balance).toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	const formattedContractBal = Number(contractBal).toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	const fetchAllowance = useCallback(async () => {
		try {
			if (account && selectedAsset && selectedExchange) {
				const assetContract = getErc20Contract(
					selectedAsset.address,
					provider.getSigner(account).connectUnchecked(),
				);
				const assetAllowance = await assetContract.allowance(
					account,
					selectedExchange.contractAddress,
				);
				setAllowance(
					formatUnits(assetAllowance, selectedAsset.decimalValue) ||
						'0',
				);
			} else {
				setAllowance('0');
			}
		} catch {
			// handle error silently
		}
	}, [account, provider, selectedAsset, selectedExchange]);

	const fetchBalance = useCallback(async () => {
		try {
			if (account && selectedAsset && signer) {
				const assetContract = getErc20Contract(
					selectedAsset.address,
					signer.provider.getSigner(account).connectUnchecked(),
				);
				const assetBalance = await assetContract.balanceOf(account);
				setBalance(
					formatUnits(assetBalance, selectedAsset.decimalValue),
				);
			} else {
				setBalance('0');
			}
		} catch {
			// handle error silently
		}
	}, [account, signer, selectedAsset]);

	const fetchContractBalance = useCallback(async () => {
		try {
			if (account && selectedExchange && selectedAsset && signer) {
				dispatch(
					appActions.setLoading({
						loading: true,
						message: 'Please Wait...',
					}),
				);
				const contract = getDepositContract(
					selectedExchange.contractAddress || '',
					signer.provider.getSigner(account).connectUnchecked(),
				);

				// fetch user's contract balance
				const userContractBalance = await contract.balanceOf(account);
				const userContractBal = await contract.convertToAssets(
					userContractBalance,
				);
				setContractBal(
					formatUnits(userContractBal, selectedAsset.decimalValue) ||
						'0',
				);
			} else {
				setContractBal('0');
			}
		} catch {
			// handle error silently
		} finally {
			dispatch(appActions.setLoading({ loading: false, message: '' }));
		}
	}, [account, dispatch, selectedExchange, signer, selectedAsset]);

	const onDeposit = async () => {
		try {
			setLoading(true);

			if (!account || !selectedAsset || !selectedExchange || !signer)
				return;

			const assetContract = getErc20Contract(
				selectedAsset.address,
				signer.provider.getSigner(account).connectUnchecked(),
			);

			const assetBalance = await assetContract.balanceOf(account);
			const requiredAmount = parseUnits(
				amount,
				selectedAsset.decimalValue,
			);

			if (assetBalance.lt(requiredAmount)) {
				toast('Wallet does not have sufficient funds to deposit.', {
					type: 'error',
				});
				return;
			}

			const contract = getDepositContract(
				selectedExchange.contractAddress || '',
				signer.provider.getSigner(account).connectUnchecked(),
			);

			// Check allowance
			if (parseFloat(allowance) < parseFloat(amount)) {
				const tx = await assetContract.approve(
					selectedExchange.contractAddress,
					requiredAmount,
				);
				await assetContract.provider.waitForTransaction(tx.hash);

				const updatedAllowance = await assetContract.allowance(
					account,
					selectedExchange.contractAddress,
				);

				if (updatedAllowance.lt(requiredAmount)) {
					toast('Please allow USDC spending', { type: 'error' });
					return;
				}
			}

			const dpt = await contract.deposit(requiredAmount, account);
			await dpt.wait();

			await userDeposit({
				contract: selectedExchange.contractAddress || '',
				address: account,
				amount: amount,
			});

			toast('Successfully staked into vault contract', {
				type: 'success',
			});
			setAmount('0');
		} catch (e: any) {
			toast(
				getErrorMessage(e?.error?.code || e?.code, e) ||
					'Something went wrong',
				{
					type: 'error',
				},
			);
		} finally {
			fetchContractBalance();
			fetchBalance();
			fetchAllowance();
			setLoading(false);
		}
	};

	const onPercentClick = (percent: number) => {
		// Use raw balance instead of formattedBalance to avoid locale issues
		const newAmount = ((Number(balance) * percent) / 100).toFixed(3);
		setAmount(newAmount);
	};

	useEffect(() => {
		fetchContractBalance();
		fetchAllowance();
		fetchBalance();
	}, [fetchAllowance, fetchBalance, fetchContractBalance]);

	return (
		<>
			<SpaceBetween>
				<Column>
					<AmountInputBox
						currency={{
							icon: selectedVault.token[0].icon,
							name: selectedVault.token[0].name,
						}}
						text={amount}
						onChange={setAmount}
						leftTxt={`${selectedVault.currency} ${formattedBalance}`}
						rightTxt={`${selectedVault.currency} ${formattedContractBal}`}
					/>
					<Box height={16} />
					<Row>
						<Text onClick={() => onPercentClick(0)}>0%</Text>
						<Text onClick={() => onPercentClick(25)}>25%</Text>
						<Text onClick={() => onPercentClick(50)}>50%</Text>
						<Text onClick={() => onPercentClick(100)}>100%</Text>
					</Row>
					<Box height={10} />
					<StyledRow>
						{VAULTS[appState.selectedNetwork].map(
							(vault: Vault) => (
								<ItemCard key={vault.name} {...vault} />
							),
						)}
					</StyledRow>

					<Box height={16} />
					<hr />
					<Box height={5} />
				</Column>
				<Column>
					<StyledButton
						onClick={() => {
							if (!isConnected) {
								dispatch(appActions.openDrawer(true));
							} else if (selectedExchange?.depositDisabled) {
								toast('Service is currently unavailable.', {
									type: 'default',
								});
							} else {
								onDeposit();
							}
						}}
						disabled={
							loading ||
							parseFloat(amount || '0') === 0 ||
							isDisabled
						}
						sx={{ height: 52 }}
						variant="contained"
						fullWidth
					>
						<Typography
							variant={'button'}
							fontWeight="bold"
							py="6px"
							color={'#fff'}
							style={{ lineHeight: '120%' }}
						>
							{!isConnected
								? 'Connect'
								: isDisabled
								? disabledMessage
								: loading
								? 'Please wait...'
								: 'Deposit'}
						</Typography>
					</StyledButton>
					<Box height={32} />
				</Column>
			</SpaceBetween>
		</>
	);
};
