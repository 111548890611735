import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { networkConfig } from 'config';
import WalletIcon from '@mui/icons-material/Wallet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getErc20Contract } from 'utils/contractHelpers';
import { formatUnits } from 'ethers/lib/utils';
import { appActions } from 'store/app';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount, useDisconnect } from 'wagmi';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { NetworkTitle } from 'components/WalletDialog/NetworkTitle';

const TextButton = styled(Button)`
	border-radius: 50px;
	background-color: #eae4d9cc;
	font-weight: 600;
	font-size: 20px;
	padding: 5px 20px;
	color: rgba(157, 108, 15, 0.8);

	:hover {
		background-color: rgba(229, 221, 204, 0.8);
	}
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const StyledButton = styled(Button)`
	display: flex;
	padding: 12px 32px;
	box-shadow: 0 4px 4px transparent;
	border-radius: 8px;
	overflow: hidden;
	background-color: #b9886d;

	:hover {
		background-color: #bb805f;
		box-shadow: 0 4px 4px transparent;
	}
`;

export const ConnectionButton = () => {
	const appState = useSelector((state: RootState) => state.app);
	const dispatch = useDispatch();
	const [balance, setBalance] = useState('0');
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const { openConnectModal } = useConnectModal();
	const { address } = useAccount();
	const { disconnect } = useDisconnect();
	const signer: any = useEthersSigner();
	const mainNet = [{ ...networkConfig.Base }];

	const bal = parseFloat(parseFloat(balance).toFixed(2)).toLocaleString();

	const addr = address ? address : '';

	const toggleDrawer = (Open: boolean) => (event: any) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		dispatch(appActions.openDrawer(Open));
	};

	const fetchBalance = useCallback(async () => {
		try {
			if (address) {
				const assetContract = getErc20Contract(
					selectedAsset?.address || '',
					signer?.provider.getSigner(address),
				);
				const assetBalance = await assetContract.balanceOf(address);
				setBalance(
					formatUnits(assetBalance, selectedAsset?.decimalValue),
				);
			} else {
				setBalance('0');
			}
		} catch (e) {
			//
		}
	}, [address, signer, selectedAsset?.address, selectedAsset?.decimalValue]);

	const ConnectedPanel = () => {
		return (
			<>
				<Typography
					variant={'subtitle2'}
					color={'#777'}
					fontWeight={600}
				>
					Choose a Network
				</Typography>
				<Box height={16} />
				{mainNet.map((d, index) => (
					<NetworkTitle key={index} {...d} />
				))}

				<Box height={64} />
				{openConnectModal && (
					<StyledButton
						onClick={() => {
							dispatch(appActions.openWallet(true));
						}}
						sx={{
							height: 42,
						}}
						variant="contained"
						fullWidth
						color="primary"
					>
						<Typography
							variant={'button'}
							fontWeight="bold"
							py="6px"
							color={'#fff'}
							style={{ lineHeight: '120%' }}
						>
							Connect
						</Typography>
					</StyledButton>
				)}
			</>
		);
	};

	const DisconnectedPanel = () => {
		return (
			<>
				<Row>
					<Row style={{ justifyContent: 'flex-start' }}>
						<WalletIcon sx={{ color: '#333' }} />
						<Box width={10} />
						<abbr
							title={addr?.toString()}
							style={{ textDecoration: 'none' }}
						>
							<Typography>
								{addr?.slice(0, 3)}...
								{addr?.slice(addr.length - 3, addr.length)}
							</Typography>
						</abbr>
					</Row>
				</Row>
				<Box height={20} />
				<Typography variant={'h5'} fontWeight={600} color={'#999'}>
					{selectedVault.currency}&nbsp;
					{bal}
				</Typography>
				<Box height={10} />
				<Box height={32} />
				<Typography variant={'subtitle2'} color={'#777'}>
					Choose a Network
				</Typography>
				<Box height={10} />
				{mainNet.map((d, index) => (
					<NetworkTitle key={index} {...d} />
				))}
				<Box height={40} />
				<StyledButton
					onClick={() => {
						disconnect();
					}}
					sx={{
						height: 42,
					}}
					variant="contained"
					fullWidth
					color="primary"
				>
					<Typography
						variant={'button'}
						fontWeight="bold"
						py="6px"
						color={'#fff'}
						style={{ lineHeight: '120%' }}
					>
						Disconnect
					</Typography>
				</StyledButton>
			</>
		);
	};

	useEffect(() => {
		fetchBalance();
	}, [fetchBalance]);

	const drawerContent = (
		<Box
			sx={{ width: 300, padding: 1.3, paddingTop: 3 }}
			role="presentation"
			onKeyDown={toggleDrawer(false)}
		>
			{!address && <ConnectedPanel />}
			{address && <DisconnectedPanel />}
		</Box>
	);

	return (
		<div>
			{!address && (
				<TextButton onClick={toggleDrawer(true)}>Connect</TextButton>
			)}
			{address && (
				<TextButton onClick={toggleDrawer(true)}>
					{addr.slice(0, 3)}...
					{addr.slice(addr.length - 3, addr.length)}
				</TextButton>
			)}
			<Drawer
				anchor="right"
				open={appState.isDrawerOpen}
				onClose={toggleDrawer(false)}
				style={{ zIndex: 100 }}
			>
				{drawerContent}
			</Drawer>
		</div>
	);
};
