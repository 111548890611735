import moment from 'moment';

export const hasOneHourPassed = (startTime: string) => {
	const currentTime = moment();
	const startTimeMoment = moment(startTime);

	const differenceInHours = currentTime.diff(startTimeMoment, 'hours');

	return differenceInHours < 1;
};

export const hasXWeekPassed = (startTime: string, week: number) => {
	const currentTime = moment();
	const startTimeMoment = moment(startTime);

	const differenceInMinutes = currentTime.diff(startTimeMoment, 'week');

	return differenceInMinutes < week;
};

export const getLockMessage = () => {
	const forcedUnlockMoment = moment(
		'2025-01-02 00:00:00',
		'YYYY-MM-DD HH:mm:ss',
	);
	const now = moment();

	if (now.isBefore(forcedUnlockMoment)) {
		return `Claim at ${forcedUnlockMoment.format('DD-MMM, hh:mm A')}`;
	}

	return 'Unlocked';
};

export const isLocked = () => {
	const forcedUnlockMoment = moment(
		'2025-01-02 00:00:00',
		'YYYY-MM-DD HH:mm:ss',
	);
	const now = moment();

	// If the current time is before 2 Jan 2025, stay locked
	return now.isBefore(forcedUnlockMoment);
};

// Usage
export const checkLock = () => {
	return getLockMessage();
};
