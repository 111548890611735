import React from 'react';
import { Box } from '@mui/material';

import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { ConnectionButton } from 'components/Navbar/ConnectButton';
import { Gutter } from 'components/Gutter';

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const navContainer = css`
	height: 65px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 40px;
`;

const NavContainer = styled(Box)`
	${navContainer}
`;

const Navbar: React.ComponentType = () => {
	return (
		<>
			<NavContainer
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<Row>
					<img
						src="/images/rndm-logo.png"
						height={36}
						alt={'movement'}
					/>
					<Box width={64} />
				</Row>

				<Box display="flex" alignItems="center">
					<Gutter gap={1} />
					<ConnectionButton />
				</Box>
			</NavContainer>
		</>
	);
};

export { Navbar };
